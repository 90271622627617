import {FormsModule} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {Component, ElementRef, Inject, Input, ViewChild} from '@angular/core';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatProgressBarModule} from '@angular/material/progress-bar';

import {MatInputModule} from '@angular/material/input';
import {NgxFileDropComponent, NgxFileDropEntry, NgxFileDropModule} from 'ngx-file-drop';
import {CommonModule} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import {FileListService} from '../../services/file-list.service';
import {DataShareService} from '../../services/data-share.service';
import {KeycloakService} from 'keycloak-angular';
import {ServerAPIError} from 'src/app/models/server-error.model';
import {GetFilesResponse, GetFileStruture} from 'src/app/models/files.model';
import {KeycloakProfile} from 'keycloak-js';
import {
  MatAutocompleteModule,
  MatAutocompleteSelectedEvent,
  MatAutocompleteTrigger
} from '@angular/material/autocomplete';


@Component({
    selector: 'app-upload-dialog',
    templateUrl: './upload-dialog.component.html',
    styleUrls: ['./upload-dialog.component.css'],
    standalone: true,
    imports: [
      CommonModule,
      NgxFileDropModule,
      FormsModule,
      MatDialogModule,
      MatFormFieldModule,
      MatProgressBarModule,
      MatInputModule,
      MatIconModule,
      MatAutocompleteModule


    ],
})
export class UploadDialogComponent {

  files: NgxFileDropEntry[] = [];
  tags: string = '';
  @Input() userProfile: KeycloakProfile | null = null;
  public filesToUpload: Array<NgxFileDropEntry> = [];
  extensions: Array<string> = [];
  filters: Array<{ key: 'owner' | 'type'; value: string }> = [];
  apiCalling: boolean = false;
  fileUploaded: boolean = false;
  apiError!: string | undefined;
  private company!: string;
  private auth_token!: string;
  showHideFileZone = false;
  fileResponses: GetFilesResponse[] = [];
  mappedFiledResponses: Array<GetFileStruture> = [];
  emailsList: Array<string> = [];

  tagInput = ''; // Holds the user's input for tags
  tagSuggestions: string[] = []; // Replace with your existing tags list
  filteredSuggestions: string[] = []; // Suggestions to display

  @ViewChild('tagInputField') tagInputField!: ElementRef<HTMLInputElement>;
// @ViewChild(MatAutocompleteTrigger) autocompleteTrigger!: MatAutocompleteTrigger;
  @ViewChild('auto') autocompleteTrigger!: MatAutocompleteTrigger;
  @ViewChild('ngxFile') ngxFile: NgxFileDropComponent | undefined;


  constructor(
      public dialogRef: MatDialogRef<UploadDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any,
      private fileListService: FileListService,
      public dataShareService: DataShareService,
      private keycloakService: KeycloakService
  ) {

  /**
   * For reasons not yet clear to me, the auth_token gets set to undefined
   * so it needs to be captured here and added manually when calling the file service
   * APIs.
   */
  this.keycloakService.getToken().then((token) => {
      // console.log('constructor', token);
      this.auth_token = token;
  });

  this.dataShareService.selectedCompanyObservable.subscribe((next) => {
      console.log(
          `UploadFilesComponent. selected company updated! ${next}`
      );
      this.company = next.toLowerCase();

      if (next && next.length) {
          this.updateFileList(next);
      } else {
          this.fileResponses = [];
      }
  });


}


openFileSelectorAndResetFlag() {
    this.fileUploaded = false; // Reset the flagt
    this.apiError = undefined;
    if (this.ngxFile) {
        this.ngxFile.openFileSelector(); // Open the file selector
    }

  }

onInputChange() {
    // Filter tag suggestions based on user input
    this.filteredSuggestions = this.filterTags(this.tagInput);
  }

  onTagSelected(event: MatAutocompleteSelectedEvent) {
    // Handle when a suggestion is selected
    this.tagInput = ''; // Clear the input after selecting a suggestion
  }

  onEnterKeyUp(event: KeyboardEvent) {
    if (event.key === 'Enter' || event.key === ',') {
      this.filteredSuggestions = this.filterTags(this.tagInput);
      this.autocompleteTrigger.openPanel();
    }
  }



  filterTags(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.tagSuggestions.filter(tag => tag.toLowerCase().includes(filterValue));
  }

  onKeyDown(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.filteredSuggestions = this.filterTags(this.tagInput);
      setTimeout(() => {
        this.autocompleteTrigger.openPanel();
      });
    }
  }



  showTagSuggestions() {
    // Filter tag suggestions based on user input
    this.filteredSuggestions = this.filterTags(this.tagInput);

    // Use setTimeout to open the autocomplete panel after a short delay
    setTimeout(() => {
      this.autocompleteTrigger.openPanel();
    }, 100); // You can adjust the delay as needed
  }








private updateFileList(company: string) {
  company=company.toLowerCase()
  this.apiCalling = true;
  this.apiError = undefined;
  this.fileListService.getFiles(company).subscribe({
      next: (next) => {
          console.log('nexttt', next);
          // FIXME: For now, we filter locally to only get books belonging to this user.  What should the data access really be?
          this.fileResponses = next.filter(
              (item) => item.email === this.keycloakService.getUsername()
          );

          this.mappedFiledResponses = [];
          next.forEach((item) => {
              if (
                  !this.emailsList.find((email) => email === item.email)
              ) {
                  this.emailsList.push(item.email);
              }

              item.files.forEach((file) => {
                  if (
                      !this.extensions.find((ext) =>
                          file.filename.endsWith(ext)
                      )
                  ) {
                      this.extensions.push(file.filename.split('.')[1]);
                  }

                  if (file.tags) {
                    console.log("Tags found for file ", file.filename, file.tags);
                    // Check if file.tags is not null or undefined before calling split
                    if (file.tags.length > 0) {
                      this.tagSuggestions.push(...file.tags);
                  }
                  this.tagSuggestions = Array.from(new Set(this.tagSuggestions));
                }

                  this.mappedFiledResponses.push({
                      filename: file.filename,
                      file_id: file.file_id,
                      isSelected: false,
                      owner: item.email,
                      tags:file.tags
                  });

              });
          });

          console.log('hereeeee', this.mappedFiledResponses);
      },
      error: (err: ServerAPIError) => {
          console.error('unable to load files: error', err);
          this.apiError = `${err.message}`;
          this.apiCalling = false;
          this.fileUploaded=false


      },
      complete: () => {
          console.log('File list updated');
          this.apiCalling = false;
          this.fileUploaded=false

      },
  });
}

  dropped(files: NgxFileDropEntry[]): void {
      console.log("Droped called")
      this.files = files;
  }

  fileOver(event: any): void {
      // Optional: handle file hover over the drop zone
  }

  fileLeave(event: any): void {
      // Optional: handle file leaving the drop zone
  }

  cancel(): void {
      this.dialogRef.close();
  }

  uploadFiles(files: File[]) {
    this.apiCalling = true;
    this.tags = this.tagInput.trim();
    console.log("Tags going to upload :",this.tags)
    this.fileListService.uploadFiles(this.company, files, this.tags).subscribe({
      next: (next) => {
        this.dataShareService.broadcastFilesUpdated();
      },
      error: (err: any) => {
        console.log("error came in calling api:", err);
        this.apiError = this.getErrorMessage(err);
        this.apiCalling = false; // Indicate API call is complete
        this.fileUploaded = false; // Indicate the file was not uploaded successfully
      },
      complete: () => {
        this.apiCalling = false;
        this.fileUploaded = true;
      },
    });
  }

  private getErrorMessage(err: any): string {
    console.log("Error recieved is",err)
    try {
      if (err.status === 500 && err.error && err.error.status === "partially uploaded") {
        const uploadedFiles = err.error.upload_success.map((file: any) => file.filename).join(', ');
        const failedFiles = err.error.upload_failed.map((file: any) => file.file).join(', ');
        return `Partial upload: Uploaded: ${uploadedFiles} ,Failed upload: ${failedFiles}`;
      } else if (err.status === 500 && err.error && err.error.status === "upload failed") {
        return `Upload failed`;
      } else if (err.status === 400 && err.error && err.error.message) {
        return `${err.error.message}`;
      } else {
        return `Error in upload: ${err.error.message || 'Unknown error'}`;
      }
    } catch (e) {
      console.error('Error processing the upload response:', e);
      return `Error uploading the file: ${err.error.message}`;
    }
  }


  upload(): void {
    const files: File[] = [];
    let filesProcessed = 0;

    for (const droppedFile of this.files) {
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          files.push(file);
          filesProcessed++;
          if (filesProcessed === this.files.length) {
            this.uploadFiles(files);
          }
        });
      }
    }
  }



}
