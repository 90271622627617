
/**
 * Model of what's POSTed to the API to rate an answer.
 */
export class RateAnswerModel {

  private thumbsDown: boolean;
  private thumbsUp: boolean;
  private questionUUID: string;
  constructor(questionUUID: string, thumbsUp: boolean, thumbsDown: boolean) {
    this.questionUUID = questionUUID;
    this.thumbsUp = thumbsUp;
    this.thumbsDown = thumbsDown;
  }
}
