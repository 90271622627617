<mat-dialog-content class="upload-dialog-content">
  <h2>Upload File</h2>
  <ngx-file-drop #ngxFile (onFileDrop)="dropped($event)" (onFileOver)="fileOver($event)" (onFileLeave)="fileLeave($event)">
    <ng-template ngx-file-drop-content-tmp>
      <button mat-raised-button (click)="openFileSelectorAndResetFlag()">Browse Files</button>
      <mat-icon *ngIf="files.length > 0">attachment</mat-icon>
      <ul>
        <li *ngFor="let file of files">{{ file.relativePath }}</li>
      </ul>
    </ng-template>
  </ngx-file-drop>
  


  <mat-form-field class="full-width">
    <input matInput placeholder="Enter Tags" [(ngModel)]="tagInput" (input)="onInputChange()" [matAutocomplete]="auto" (keydown)="onKeyDown($event)">
    <mat-autocomplete #auto="matAutocomplete">
      <mat-option *ngFor="let suggestion of filteredSuggestions" [value]="suggestion">
        {{ suggestion }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>

  @if (fileUploaded) {
    <span class="upload-success">File uploaded successfully</span>
  }

  @if (this.apiError) {
    <span class="upload-error">{{apiError}}</span>
  }

  

</mat-dialog-content>
@if (apiCalling) {
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  }


<mat-dialog-actions>
  <button mat-button (click)="cancel()">Close</button>
  <button mat-button (click)="upload()">Upload</button>
</mat-dialog-actions>
