import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {
  AudioConfig,
  PropertyId,
  ResultReason,
  SpeakerAudioDestination,
  SpeechConfig,
  SpeechRecognizer,
  SpeechSynthesisOutputFormat,
  SpeechSynthesizer
} from 'microsoft-cognitiveservices-speech-sdk';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {TokenService} from 'src/app/services/token-service';
import {MatIconModule} from "@angular/material/icon";

@Component({
  selector: 'app-audio-recorder',
  templateUrl: './audio-recorder.component.html',
  styleUrls: ['./audio-recorder.component.scss'],
  standalone: true,
  imports: [CommonModule, FormsModule, MatIconModule],
})
export class AudioRecorderComponent implements OnInit {
  @Output() transcription = new EventEmitter<string>();

  private speechRecognizer!: SpeechRecognizer;
  private speechSynthesizer: SpeechSynthesizer | null = null;
  public isListening = false;
  private audioConfig = AudioConfig.fromDefaultMicrophoneInput();
  private speechConfig!: SpeechConfig;
  private audioDestination: SpeakerAudioDestination | null = null;
  public tempraryMute = false;

  constructor(private tokenService: TokenService) {}

  async ngOnInit() {
    const tokenData = await this.tokenService.getTokenOrRefresh();
    if (tokenData?.token) {
      this.speechConfig = SpeechConfig.fromAuthorizationToken(tokenData.token, tokenData.region);
      this.speechConfig.setProperty(PropertyId.SpeechServiceConnection_LanguageIdMode, 'Continuous');
      this.speechConfig.setProperty(PropertyId.SpeechServiceConnection_SynthOutputFormat, SpeechSynthesisOutputFormat[SpeechSynthesisOutputFormat.Audio16Khz32KBitRateMonoMp3].toString());
      this.speechRecognizer = new SpeechRecognizer(this.speechConfig, this.audioConfig);
      this.audioDestination = new SpeakerAudioDestination();
      this.speechSynthesizer = new SpeechSynthesizer(this.speechConfig, AudioConfig.fromSpeakerOutput(this.audioDestination));

      this.speechRecognizer.recognized = (_, event) => {
        if (event.result.reason === ResultReason.RecognizedSpeech) {
          this.transcription.emit(event.result.text);
        }
      };

      this.speechRecognizer.sessionStarted = () => {
        this.isListening = true;
      };

      this.speechRecognizer.sessionStopped = () => {
        this.isListening = false;
        this.speechRecognizer.stopContinuousRecognitionAsync();
      };

      this.speechRecognizer.canceled = (_, event) => {
        this.stopListening();
      };

      // Add event listener for audio playback end
      this.audioDestination.onAudioEnd = () => {
        console.log('Audio playback completed.');
        this.isListening = true;
        this.tempraryMute = false;
        this.startListening();
      };
    } else {
      console.error('Failed to obtain authorization token.');
    }
  }

  startListening() {
    this.speechRecognizer.startContinuousRecognitionAsync();
  }

  stopListening() {
    this.isListening = false;
    this.speechRecognizer.stopContinuousRecognitionAsync(
      () => {
        this.stopSpeaking();
      },
      (err) => {
        this.stopSpeaking();
      }
    );
  }

  stopSpeaking() {
    if (this.audioDestination) {
      this.audioDestination.pause();
      this.audioDestination = null;
    }
    if (this.speechSynthesizer) {
      this.speechSynthesizer.close();
      this.speechSynthesizer = null;
    }
  }

  stopPlayingAudio() {
    this.tempraryMute = false;
    this.isListening = true;
    if (this.audioDestination) {
      this.audioDestination.pause();
    }
    if (this.speechSynthesizer) {
      this.speechSynthesizer.close();
      this.speechSynthesizer = null;
    }
    this.startListening();
  }

  convertTextToSpeech(text: string) {
    this.stopSpeaking();
    this.isListening = false;
    this.tempraryMute = true;
    this.speechRecognizer.stopContinuousRecognitionAsync();

    const ssmlText = `
      <speak version="1.0" xmlns="http://www.w3.org/2001/10/synthesis" xml:lang="en-US">
        <voice name="en-US-JennyNeural">
          ${text}
        </voice>
      </speak>
    `;

    if (!this.speechSynthesizer) {
      this.audioDestination = new SpeakerAudioDestination();
      this.speechSynthesizer = new SpeechSynthesizer(this.speechConfig, AudioConfig.fromSpeakerOutput(this.audioDestination));
    }
    if (this.speechSynthesizer) {
      this.speechSynthesizer.speakSsmlAsync(ssmlText,
        (result) => {
          if (result.reason === ResultReason.SynthesizingAudioCompleted) {
            console.log('Speech synthesis finished.');
          } else {
            console.error('Speech synthesis canceled, ' + result.errorDetails);
          }
          this.speechSynthesizer?.close();
          this.speechSynthesizer = null;
          this.startListening(); // Restart listening after speech synthesis
        },
        (error) => {
          console.error(error);
          this.speechSynthesizer?.close();
          this.speechSynthesizer = null;
          this.startListening(); // Restart listening after error
        });
    }

    console.log("starting listening again");
    console.log("isListening:", this.isListening);
  }
}
