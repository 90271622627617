import {API_HEADER_COMPANY, API_HEADER_FILE_ID, API_HEADER_LLMNAME, API_HEADER_QUERY_TYPE} from './file-list.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {GetFileStruture} from '../models/files.model';
import {LLMAnswer} from '../models/llmanswer.model';
import {UtilsService} from './utils.service';
import {GlobalVars} from './config.service';
import {catchError, Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {EnumQueryType} from "../components/ama/ama.component";
import {RateAnswerModel} from "../models/rate-answer.model";

// Hardcoded file id for now - this references the file that has the embeddings of the SQL schema + comments
export const API_VALUE_SQL_FILE_ID = '7dfe8afe-5ec3-47ac-a9e6-b6ee817f3d6e';

@Injectable({
    providedIn: 'root',
})
export class QuestionService {
    constructor(private http: HttpClient) {
    }

    /**
     * Ask a question via the LLM REST API
     * @param company - company the user is associated with
     * @param question - what is the question to ask?
     * @param queryType - is this a question for the docs or for the SQL table.
     * @param selectedFiles - what documents (already in the embedded folder) should this question be asked of
     * @param conversationId - id to maintain history of specific conversation
     * @param selectedLLM - the name of the LLM to use (which must be configured in CoPilot API)
    */
    askQuestion(
        company: string,
        question: string,
        queryType: EnumQueryType,
        selectedFiles?: GetFileStruture[],
        conversationId?: string,
        selectedLLM?: string
    ): Observable<LLMAnswer> {
        let headers = new HttpHeaders();
        headers = headers.set(API_HEADER_COMPANY, company);

        let ids = '';

        if (queryType === EnumQueryType.Docs) {
            selectedFiles?.forEach((element, index) => {
                ids += element.file_id;

                if (selectedFiles.length - 1 !== index) {
                    ids += ',';
                }
            });
            if (selectedFiles && selectedFiles.length) {
                headers = headers.set(API_HEADER_FILE_ID, ids);
            }
        } else {
            headers = headers.set(API_HEADER_FILE_ID, API_VALUE_SQL_FILE_ID);
        }
        if (selectedLLM) {
          headers = headers.set(API_HEADER_LLMNAME, selectedLLM);
        }
        headers = headers.set(API_HEADER_QUERY_TYPE, queryType);

        const url = `${GlobalVars.appConfig.questionServiceUrl}/chat/`;
        return this.http
            .post<LLMAnswer>(
                url,
                {
                    question,
                    'conversation-id': conversationId,
                },
                {headers: headers}
            )
            .pipe(catchError(UtilsService.handleError));
    }


  /**
   * Ask a question via the LLM REST API
   * @param company - company the user is associated with
   * @param questionUUID - Unique ID of the question/answer
   * @param thumbsUp - true if user happy with answer
   * @param thumbsDown - true if user not happy with answer.
   */
  rateAnswer(
    company: string,
    questionUUID: string,
    thumbsUp: boolean,
    thumbsDown: boolean
  ): Observable<boolean> {
    let headers = new HttpHeaders();
    headers = headers.set(API_HEADER_COMPANY, company);
    const rateAnswer = new RateAnswerModel(questionUUID, thumbsUp, thumbsDown);

    const url = `${GlobalVars.appConfig.questionServiceUrl}/rate_answer/`;
    return this.http
      .post<boolean>(
        url,
        rateAnswer,
        {headers: headers}
      )
      .pipe(catchError(UtilsService.handleError));
  }
}
