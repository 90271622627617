import {Component, OnInit} from '@angular/core';
import {AmaComponent} from '../ama/ama.component';
import {UploadFilesComponent} from '../upload-files/upload-files.component';
import {UserService} from 'src/app/services/user.service';
import {KeycloakProfile} from 'keycloak-js';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  standalone: true,
  imports: [UploadFilesComponent, AmaComponent],
})
export class DashboardComponent implements OnInit {
  public userProfile: KeycloakProfile | null = null;

  constructor(private userService: UserService) {}

  async ngOnInit(): Promise<void> {
    const isLoggedIn = await this.userService.isLoggedIn();
    if (isLoggedIn) {
      this.userProfile = await this.userService.loadUserProfile();
    }
  }
}
