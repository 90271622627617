import {Component, EventEmitter, Input, Output} from '@angular/core';
import {DataShareService} from 'src/app/services/data-share.service';
import {MatTooltipModule} from '@angular/material/tooltip';
import {GetFileStruture} from 'src/app/models/files.model';
import {UserService} from 'src/app/services/user.service';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import {CommonModule} from '@angular/common';

@Component({
    selector: 'app-list-selection',
    templateUrl: './list-selection.component.html',
    styleUrl: './list-selection.component.scss',
    standalone: true,
    imports: [
        MatListModule,
        MatIconModule,
        CommonModule,
        MatTooltipModule,
        MatMenuModule,
    ],
})
export class ListSelectionComponent {
    @Output() emptyFilters = new EventEmitter();
    @Output() onDeleteItem = new EventEmitter();
    @Output() onDownloadItem = new EventEmitter();

    @Input() list: Array<GetFileStruture> = [];
    @Input() searchValue!: { search: string; type: string; owner: string ; tag: string };
    @Input() filters: Array<{ key: 'owner' | 'type' | 'tag' ; value: string }> = [];

    filesArray: Array<GetFileStruture> = [];

    constructor(
        public dataShareService: DataShareService,
        public userService: UserService
    ) {}

    ngOnChanges(): void {
        this.filesArray = [];

        console.log("list:::::::::::::::::",this.list.length)


        this.list.forEach((item) => {


            const fileMatch = {
                owner: false,
                type: false,
                search: false,
                tag: false,
            };

            // validate matching search value
            if (this.searchValue?.search) {
                if (
                    item.filename
                        .toLowerCase()
                        .includes(this.searchValue.search.toLowerCase())
                ) {
                    fileMatch.search = true;
                }
            } else {
                fileMatch.search = true;
            }

            // validate matching owner
            if (this.searchValue?.owner && this.searchValue.owner !== 'all') {
              console.log("item list selection:",item)
                if (item.owner === this.searchValue.owner) {
                    fileMatch.owner = true;
                }
            } else {
                fileMatch.owner = true;
            }

            // validate matching tag
            if (this.searchValue?.tag && this.searchValue.tag !== 'all') {
              console.log("tag to search:",this.searchValue?.tag)

              console.log("tag to search in an array:",item.tags)
              const tagExists = item.tags && this.searchValue?.tag ? item.tags.includes(this.searchValue?.tag) : false;


              if (tagExists) {
                fileMatch.tag = true;
                          }
            } else {
                fileMatch.tag = true;
            }


            // validate matching file type
            if (this.searchValue?.type && this.searchValue.type !== 'all') {
                if (
                    item.filename.endsWith(this.searchValue.type.toLowerCase())
                ) {
                    fileMatch.type = true;
                }
            } else {
                fileMatch.type = true;
            }

            if (fileMatch.owner && fileMatch.tag && fileMatch.type && fileMatch.search) {
                this.filesArray.push(item);
            }
        });
    }

    isListItemClicked(index: number): void {
        if (this.filesArray[index]?.isSelected) {
            this.filesArray[index].isSelected = false;
        } else {
            this.filesArray[index]['isSelected'] = true;
        }

        const selected: any = [];
        this.filesArray.forEach((item) => {
            if (item.isSelected) {
                selected.push({
                    filename: item.filename,
                    file_id: item.file_id,
                });
            }
        });
        this.dataShareService.selectedFiles = selected;

        this.dataShareService.broadcastSelectedFilesObservable(selected);
    }

    unselectAll(): void {
        this.dataShareService.selectedFiles = [];

        this.list = this.list.map((element) => {
            element.isSelected = false;
            return element;
        });
        this.filesArray = this.filesArray.map((element) => {
            element.isSelected = false;
            return element;
        });
    }

    clearFilters(): void {
        this.emptyFilters.emit(true);
    }

    onDelete(item: GetFileStruture): void {
        this.onDeleteItem.emit(item);
    }
    onDownload(item: GetFileStruture): void {
        this.onDownloadItem.emit(item);
    }

}
