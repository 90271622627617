import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {CommonModule} from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {marked} from 'marked';

@Component({
  selector: 'app-detailed-answer-dialog',
  templateUrl: './detailed-answer-dialog.component.html',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
  ],
})
export class DetailedAnswerDialogComponent implements OnInit {
  sanitizedAnswer: SafeHtml = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { answer: string },
    private dialogRef: MatDialogRef<DetailedAnswerDialogComponent>,
    private sanitizer: DomSanitizer
  ) {}

  async ngOnInit() {
    const rawMarkup = await marked(this.data.answer); // Await the promise if marked is asynchronous
    this.sanitizedAnswer = this.sanitizer.bypassSecurityTrustHtml(rawMarkup);
  }

  onClose(): void {
    this.dialogRef.close();
  }
}
