import {DateTime} from "luxon";

import {Observable, throwError} from "rxjs";
import {HttpErrorResponse} from "@angular/common/http";
import {ServerAPIError} from "../models/server-error.model";


/**
 * Util methods used across the services.
 */
export class UtilsService {

  // https://moment.github.io/luxon/docs/manual/formatting.html#table-of-tokens
  public static readonly LUXON_FORMAT_FOR_SENDING_TO_NOTIFICATION_SERVICE = `yyyy-MM-dd'T'HH:mm:ssZZZ`;

  /**
   * Format the date into a UTC format accepted by the Flight Service API endpoint
   */
  static getAsUTCDateForFlightServiceAPI(from: DateTime): string {
    let fromDateStr = from.toUTC().toString();
    fromDateStr = fromDateStr.substr(0, fromDateStr.length - 5);
    fromDateStr += 'Z';
    return fromDateStr;
  }


  /**
   * Smart append of the path onto the configured service URL
   *
   * @param serviceUrl
   * @param path
   */
  static appendURLPath(serviceUrl: string, path: string): string {
    if (serviceUrl.endsWith('/')) {
      return serviceUrl + path;
    } else {
      return serviceUrl + '/' + path;
    }
  }


  /**
   * Convert the json timestamp strings from the API (websocket or rest)  to DateTime objects.
   * This is called to handle many data structure objects so everything needs to be null checked
   */
  static convertTimestampStringsToDateTimeTypes(data: any ): any {
    if (!data) {
      return data;
    }

    // Every event has a timestamp
    if (data.timestamp) {
      data.timestamp = DateTime.fromISO(data.timestamp, { setZone: true });
    }
    if (data.from) {
      data.from = DateTime.fromISO(data.from, { setZone: true });
    }
    if (data.to) {
      data.to = DateTime.fromISO(data.to, { setZone: true });
    }
/*
    // Flight objects have estimated/scheduled/actual.
    if (data.estimated) {
      data.estimated = DateTime.fromISO(data.estimated, { setZone: true });
    }
    if (data.scheduled) {
      data.scheduled = DateTime.fromISO(data.scheduled, {setZone: true});
    }
    if (data.actual) {
      data.actual = DateTime.fromISO(data.actual, {setZone: true});
    }

    // Wait time prediction data
    if (data.predictions) {
      data.predictions.forEach(p => {
        p.timestamp = DateTime.fromISO(p.timestamp, { setZone: true });
      });
    }
    // Future Event data array
    if (data.data) {
      data.data.forEach(p => {
        p.timestamp = DateTime.fromISO(p.timestamp, { setZone: true });
      });
    }

    // Notifications have end_datetime/start_datetime
    if (data.end_datetime) {
      data.end_datetime = DateTime.fromISO(data.end_datetime, { setZone: true });
    }
    if (data.start_datetime) {
      data.start_datetime = DateTime.fromISO(data.start_datetime, { setZone: true });
    }

    if (data.event_type === DTEventTypes.flight_stand_conflict) {
      data.time_of_overlap.from = DateTime.fromISO(data.time_of_overlap.from, { setZone: true });
      data.time_of_overlap.to = DateTime.fromISO(data.time_of_overlap.to, { setZone: true });
    }

    // From FlightsOnStand response object.
    if (data instanceof FlightsOnStand) {
      if (data.flightAllocationList) {
        data.flightAllocationList.forEach(f => {
          f.scheduled = DateTime.fromISO(f.scheduled.toString(), { setZone: true });
        });
      }
      if (data.flightAtStandAllocationSchedules) {
        // tslint:disable-next-line:forin
        for (const flightAtStandAllocationSchedulesKey in data.flightAtStandAllocationSchedules) {
          const f = data.flightAtStandAllocationSchedules[flightAtStandAllocationSchedulesKey];
          f.startTime = DateTime.fromISO(f.startTime.toString(), { setZone: true });
          f.endTime = DateTime.fromISO(f.endTime.toString(), { setZone: true });
          f.created = DateTime.fromISO(f.created.toString(), { setZone: true });
        }
      }
    }
    // Batches of events on startup have this event_type.
    if (data.event_type === DTEventTypes.null_event && data.events) {
      data.events.forEach(e => {
        this.convertTimestampStringsToDateTimeTypes(e);
      });
    }
    // Or we could simply receive an array of Flight/Notification events (from batch update)
    if (Array.isArray(data)) {
      data.forEach(e => {
        this.convertTimestampStringsToDateTimeTypes(e);
      });
    }
*/
    return data;
  }

  /**
   * Convert HTTP error into a Digital Twin error.
   * @throws a properly formed DigitalTwinError object
   */
  static handleError(error: HttpErrorResponse): Observable<never> {
    console.error(error);
    let msg: string | undefined = undefined;
    let code: number | undefined= undefined;
    if (error.error) {
      msg = error.error.message;
      if (!msg) {
        msg = error.error.error;
      }
      code = error.error.code;
    }

    if (!msg) {
      code = error.status;
      if (error.error && error.error.errorMessage) {
        msg = error.error.errorMessage;
      } else {
        msg = error.statusText;
      }
    }
    // return an observable with a user-facing error message
    const err = new ServerAPIError(msg as string, code as number)
    return throwError(() => err);
  }
}
