import { LLMAnswer } from "./llmanswer.model";

/**
 * This class contains the elements needed to display the UI query/response
 * It stores both the query going to the server, and the resulting answer from the server (LLMAnswer)
 */
export class UiQueryResponseModel {

  private _answer: string | undefined;
  private _question: string | undefined;
  private _source: string | undefined;
  private _showThumbs: boolean;
  private _isCoPilot: boolean;
  /** Tracks if the user pressed up / down buttons for this response. */
  private _thumbUpClicked = false;
  private _thumbDownClicked = false;
  private _questionUUID: string | undefined;

  constructor(
    isCoPilot: boolean,
    showThumbs: boolean,
    query: string | undefined,
    result: string | undefined,
    sourceInfo: string | undefined,
    questionUUID: string | undefined
  ) {
    this._isCoPilot = isCoPilot;
    this._showThumbs = showThumbs;
    this._question = query;
    this._answer = result;
    this._source = sourceInfo;
    this._questionUUID = questionUUID;
  }

  get isCoPilot(): boolean {
    return this._isCoPilot;
  }

  get showThumbs(): boolean {
    return this._showThumbs;
  }

  get question(): string | undefined {
    return this._question;
  }

  get answer(): string | undefined {
    return this._answer;
  }

  get questionUUID(): string | undefined {
    return this._questionUUID;
  }

  get source(): string | undefined {
    return this._source;
  }

  get thumbUpClicked(): boolean {
    return this._thumbUpClicked;
  }

  set thumbUpClicked(value: boolean) {
    this._thumbUpClicked = value;
  }

  get thumbDownClicked(): boolean {
    return this._thumbDownClicked;
  }

  set thumbDownClicked(value: boolean) {
    this._thumbDownClicked = value;
  }

  public static getSourceText(item: LLMAnswer): string | undefined {

    // Check if a database query was made
    if (item.llm_made_db_query) {
      return `Sources -> ${item.llm_made_db_query}`;
    }

    // Check if context exists and is an array
    if (!item.context || !Array.isArray(item.context) || item.context.length === 0) {
      return undefined; // Return undefined if there is no context to show
    }

    // Initialize sets to collect unique values
    const upload_document_names: Set<string> = new Set();
    const file_ids: Set<string> = new Set();
    const sources: Set<string> = new Set();
    const pages: Set<number> = new Set(); // Assuming pages are numbers and should also be unique

    // Iterate through context
    item.context.forEach((doc) => {
      if (doc.metadata) {
        if (doc.metadata.upload_document_name) {
          upload_document_names.add(doc.metadata.upload_document_name);
        }
        if (doc.metadata.file_id) {
          file_ids.add(`"${doc.metadata.file_id}"`); // Adding quotes around the file_id
        }
        if (doc.metadata.source) {
          sources.add(doc.metadata.source);
        }
        if (doc.metadata.page) {
          pages.add(doc.metadata.page); // Directly add as Set handles uniqueness
        }
      }
    });

    // Convert Sets back to arrays if needed for further processing
    const uniqueDocumentNames = Array.from(upload_document_names);
    const uniqueFileIds = Array.from(file_ids);
    const uniqueSources = Array.from(sources);
    const uniquePages = Array.from(pages);

    // Construct the sources string
    const sourcesString = `Sources -> doc: ${uniqueDocumentNames.join(', ')}; file id: ${uniqueFileIds.join(', ')}; ${uniqueSources.join(', ')}; Pages: ${uniquePages.join(', ')}`;
    return sourcesString;
  }

}
