import {ListSelectionComponent} from '../list-selection/list-selection.component';
import {GetFilesResponse, GetFileStruture} from 'src/app/models/files.model';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {DataShareService} from '../../services/data-share.service';
import {NgxFileDropEntry, NgxFileDropModule} from 'ngx-file-drop';
import {FileListService} from '../../services/file-list.service';
import {ServerAPIError} from '../../models/server-error.model';
import {MatButtonModule} from '@angular/material/button';
import {MatSelectModule} from '@angular/material/select';
import {MatChipsModule} from '@angular/material/chips';
import {MatInputModule} from '@angular/material/input';
import {MatCardModule} from '@angular/material/card';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {KeycloakService} from 'keycloak-angular';
import {Component, Input} from '@angular/core';
import {CommonModule} from '@angular/common';
import {KeycloakProfile} from 'keycloak-js';
import {MatDialog} from '@angular/material/dialog';
import {UploadDialogComponent} from '../upload-dialog/upload-dialog.component';
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule,} from '@angular/forms';

@Component({
    selector: 'app-upload-files',
    templateUrl: './upload-files.component.html',
    styleUrls: ['./upload-files.component.scss'],
    standalone: true,
    imports: [
        MatCardModule,
        NgxFileDropModule,
        MatButtonModule,
        MatProgressBarModule,
        MatInputModule,
        MatChipsModule,
        FormsModule,
        ReactiveFormsModule,
        MatSelectModule,
        CommonModule,
        MatListModule,
        MatIconModule,
        ListSelectionComponent
    ],
})
export class UploadFilesComponent {
    @Input() userProfile: KeycloakProfile | null = null;

    //public filesToUpload: Array<NgxFileDropEntry> = [];
    public filesToUpload: File[] = [];


    extensions: Array<string> = [];
    tags: Array<string> = [];
    // filters: Array<{ key: 'owner' | 'type'; value: string }> = [];
    filters: Array<{ key: 'owner' | 'type' | 'tag' ; value: string  }> = [];
    apiCalling = false;
    apiError!: string | undefined;
    private company!: string;
    private auth_token!: string;
    showHideFileZone = false;
    searchForm: FormGroup;
    fileResponses: GetFilesResponse[] = [];
    mappedFiledResponses: Array<GetFileStruture> = [];
    emailsList: Array<string> = [];


    constructor(
        private fileListService: FileListService,
        public dataShareService: DataShareService,
        private keycloakService: KeycloakService,
        private fb: FormBuilder,
        public dialog: MatDialog,

    ) {
        this.searchForm = this.fb.group({
            type: [''],
            owner: [''],
            tag: [''],
            search: [''],
        });
        /**
         * For reasons not yet clear to me, the auth_token gets set to undefined
         * so it needs to be captured here and added manually when calling the file service
         * APIs.
         */
        this.keycloakService.getToken().then((token) => {
            // console.log('constructor', token);
            this.auth_token = token;
        });

        this.dataShareService.selectedCompanyObservable.subscribe((next) => {
            console.log(
                `UploadFilesComponent. selected company updated! ${next}`
            );
            this.company = next;

            if (next && next.length) {
                this.updateFileList(next);
            } else {
                this.fileResponses = [];
            }
        });

        this.dataShareService.filesUpdatedObservable.subscribe((update) => {
            console.log(`FileListComponent. filesUpdatedObservable! ${update}`);
            if (update && this.company) {
                this.updateFileList(this.company);
            }
        });
    }


    openDialog(): void {
      this.dialog.open(UploadDialogComponent, {
        width: '250px',
        // additional configuration if needed
      });
    }

    private updateFileList(company: string) {
        this.apiCalling = true;
        this.apiError = undefined;
        console.log("compony for which getting files :",company)
        this.fileListService.getFiles(company).subscribe({
            next: (next) => {
                console.log('found files for emails:', next);
                // FIXME: For now, we filter locally to only get books belonging to this user.  What should the data access really be?
                this.fileResponses = next.filter(
                    (item) => item.email === this.keycloakService.getUsername()
                );

                this.mappedFiledResponses = [];
                next.forEach((item) => {
                    if (
                        !this.emailsList.find((email) => email === item.email)
                    ) {
                        this.emailsList.push(item.email);
                    }

                    item.files.forEach((file) => {
                      // console.log("file: ",file);

                        if (
                            !this.extensions.find((ext) =>
                                file.filename.endsWith(ext)
                            )
                        ) {
                            this.extensions.push(file.filename.split('.')[1]);
                        }


                        // console.log("file tags: ",file.tags);
                        if (file.tags) {
                          // console.log("Tags found for file ", file.filename, file.tags);
                          // Check if file.tags is not null or undefined before calling split
                          if (file.tags.length > 0) {
                            this.tags.push(...file.tags);
                        }
                        this.tags = Array.from(new Set(this.tags));


                      }




                        this.mappedFiledResponses.push({
                            filename: file.filename,
                            file_id: file.file_id,
                            isSelected: false,
                            owner: item.email,
                            tags: file.tags,
                        });
                    });
                });

                // Assuming the tags array might have duplicates
                this.tags = Array.from(new Set(this.tags));
            },
            error: (err: ServerAPIError) => {
                console.error('unable to load files: error', err);
                this.apiError = `Error loading the list of files for this user: ${err.message}`;
                this.apiCalling = false;
            },
            complete: () => {
                console.log('COMPLETE in file upload');
                this.apiCalling = false;
            },
        });
    }

    onSearchChange(): void {
        this.filters = [];
        if (this.searchForm.value.type) {
            this.filters.push({
                key: 'type',
                value: this.searchForm.value.type,
            });
        }
        if (this.searchForm.value.owner) {
            this.filters.push({
                key: 'owner',
                value: this.searchForm.value.owner,
            });
        }

        if (this.searchForm.value.tag) {
          this.filters.push({
              key: 'tag',
              value: this.searchForm.value.tag,
          });
      }
    }

    clearFilters(): void {
        this.searchForm.patchValue({
            type: '',
            owner: '',
            search: '',
            tag: '',
        });

        this.onSearchChange();

        this.updateSelectedFiles();
    }

    updateSelectedFiles(): void {
        this.dataShareService.selectedFiles = this.mappedFiledResponses.filter(
            (element) => element.isSelected
        );
    }

    removeFilters(index: number): void {
        this.searchForm.patchValue({
            [this.filters[index].key]: '',
        });

        if (this.filters.length === 0) {
            this.searchForm.patchValue({
                type: '',
                owner: '',
                search: '',
                tag: '',
            });
        }

        this.onSearchChange();
        this.updateSelectedFiles();
    }

    public dropped(files: NgxFileDropEntry[]) {
        this.showHideFileZone = false;
        const filePromises = files.map((droppedFile) => {
          return new Promise<void>((resolve) => {
            if (droppedFile.fileEntry.isFile) {
              const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
              fileEntry.file((file: File) => {
                this.filesToUpload.push(file);  // Store File objects separately
                resolve();
              });
            } else {
              resolve();
            }
          });
        });

        Promise.all(filePromises).then(() => {
          this.uploadClicked();
        });
      }



      async uploadClicked() {
        this.apiError = undefined;
        if (this.filesToUpload.length > 0) {
          this.uploadFiles(this.filesToUpload);
        }
      }


      uploadFiles(files: File[]) {
        this.apiCalling = true;
        this.fileListService.uploadFiles(this.company, files, this.tags.join(',')).subscribe({
          next: (next) => {
            this.dataShareService.broadcastFilesUpdated();
          },
          error: (err: ServerAPIError) => {
            this.apiError = `Error uploading the file for this user: ${err.message}`;
            this.apiCalling = false;
          },
          complete: () => {
            this.apiCalling = false;
          },
        });
      }


    public fileOver(event: any) {
        this.showHideFileZone = true;
    }

    public fileLeave(event: any) {
        this.showHideFileZone = false;
    }




    deleteFile(fileItem: GetFileStruture) {
        this.apiCalling = true;
        this.apiError = undefined;
        this.fileListService.deleteFile(this.company, fileItem).subscribe({
            next: (next) => {
                console.log('Successfully deleted', next);
                console.log(this.fileResponses);
                this.dataShareService.broadcastFilesUpdated();
            },
            error: (err: ServerAPIError) => {
                console.error('unable to delete file: error', err);
                this.apiError = `Error delete the file for this user: ${fileItem.filename}. Error: ${err.message}`;
                this.apiCalling = false;
            },
            complete: () => {
                console.log('DELETE COMPLETE');
                this.apiCalling = false;
                this.updateFileList(this.company);
            },
        });
    }



    downloadDocByFileId(fileItem: GetFileStruture) {
        this.fileListService.downloadFile(this.company, fileItem.file_id).subscribe(
          (response: Blob) => {
            const url = window.URL.createObjectURL(response);
            const a = document.createElement('a');
            a.href = url;
            a.download = `${fileItem.filename}`;
            a.click();
            window.URL.revokeObjectURL(url);
          },
          (error) => {
            console.error('File download error:', error);
          }
        );
      }
}
