import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {GlobalVars} from './config.service';
import {AvatarToken} from "../models/avatar-token.model";

@Injectable({
  providedIn: 'root'
})
export class TokenService {
  constructor(private http: HttpClient) { }

  async generateToken() {
    const dpaUrl = GlobalVars.appConfig.dpaUrl;
    const dpaApiKey = GlobalVars.appConfig.dpaApiKey;

    if (!dpaUrl || !dpaApiKey) {
      console.log("dpaUrl or dpaApiKey is empty. Skipping token generation.");
      return null;
    }

    console.log("dpa url:", dpaUrl);

    const headers = new HttpHeaders({
      'x-api-key': dpaApiKey,
      'companyID': 'SITA'
    });

    const url = `${dpaUrl}/avatar/generateToken`;

    try {
      return await this.http.get<AvatarToken>(url, {headers}).toPromise();
    } catch (error) {
      console.error("Error occurred generating token from dpa service:", dpaUrl, error);
      return null;
    }
  }

  async getTokenOrRefresh() {
    try {
      const res = await this.generateToken();
      if (res) {
        return res;
      } else {
        console.error("Failed to generate token, unknown error");
        return null;
      }
    } catch (err) {
      console.error(err);
      return null;
    }
  }
}
