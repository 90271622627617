import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {GlobalVars} from "./app/services/config.service";
import {enableProdMode} from "@angular/core";
import {environment} from './environments/environment';


/**
 * This will load the config from the local file /assets/config.json on startup.
 * There are multiple config files - local, dev, prod in the release bundle
 *
 * LOCAL DEV:
 * YOU, the developer, must copy the config.local.json to config.json when in local mode.
 *
 * K8S ENVIRONMENT:
 * The gitlab ci scripts / k8s deployment config will copy the appropriate dev/prod config file into the pod
 * when deploying to the k8s environment.
 */

function validateConfig() {

  if (!GlobalVars.appConfig.environment) {
    throw new Error('appConfig.environment is not set');
  } else {
    console.log('App Environment set to ', GlobalVars.appConfig.environment);
  }

  // if (!GlobalVars.appConfig.websocketDigitalTwinUrl) {
  //   throw new Error('appConfig.websocketDigitalTwinUrl is not set');
  // }
}

fetch('/assets/config.json')
  .then(response => response.json())
  .then(config => {
    GlobalVars.appConfig  = config;
    validateConfig();

    if (environment.production) {
      enableProdMode();
    }

    platformBrowserDynamic()
      .bootstrapModule(AppModule)
      .catch(err => console.error(err));
  }).catch(e => {
  console.log('catch', e);
  window.alert('Unable to load the /assets/config.json file. There has been an error deploying the application.');
}).finally(() => {
  // console.log('onFinally');
});

