<div>
  <div class="filter-selection-header">
    <!-- filters -->
    @if(list.length !== filesArray.length) {
    <div class="selection-text">
      Showing {{ filesArray.length }} of {{ list.length }} Files
    </div>
    <div>-</div>
    <button (click)="clearFilters()" class="btn-clear">Clear Filter</button>
    } @else {
    <div class="selection-text">{{ list.length }} Files Total</div>
    }

    <!-- selection -->
    @if(dataShareService.selectedFiles.length > 0) {
      <div>-</div>
    <div class="selection-text">
       {{ dataShareService.selectedFiles.length }} Selected
    </div>
    <div>-</div>
    <button (click)="unselectAll()" class="btn-clear">Unselect</button>
    }
  </div>
  <mat-selection-list>
    <mat-divider></mat-divider>
    @for (item of filesArray; track item; let i = $index) {
    <div
      class="item-container"
      [ngStyle]="{
        'background-color': item.isSelected ? '#c5eafa' : 'transparent'
      }"
    >
      <div
        class="details"
        (click)="isListItemClicked(i)"
        matTooltip="{{ item.owner }}"
        [matTooltipShowDelay]="500"
        [matTooltipHideDelay]="300"
      >
        <mat-icon (click)="isListItemClicked(i)" class="file-icon"
          >picture_as_pdf</mat-icon
        >
        <mat-list-option>
          {{ item.filename }}
        </mat-list-option>
      </div>

      <!-- TODO : Load from user profile -->
      @if(item.owner === userService.userProfile?.email  || 
      userService.userProfile?.email?.toLowerCase() === "savita.raghuvanshi@sita.aero"){

      <mat-icon [matMenuTriggerFor]="menu" style="cursor: pointer"
        >more_horiz</mat-icon
      >

      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="onDelete(item)">
          <span>Delete</span>
        </button>
        <button mat-menu-item (click)="onDownload(item)">
          <span>Download</span>
        </button>
      </mat-menu>
      }
    </div>

    <mat-divider></mat-divider>
    }
  </mat-selection-list>
</div>
