import {GetFilesResponse, GetFileStruture} from '../models/files.model';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {KeycloakService} from 'keycloak-angular';
import {UtilsService} from './utils.service';
import {GlobalVars} from './config.service';
import {catchError, Observable} from 'rxjs';
import {Injectable} from '@angular/core';

export const API_HEADER_COMPANY = 'company';
export const API_HEADER_LLMNAME = 'llmName';
export const API_HEADER_FILE_ID = 'file-ids';
export const API_HEADER_QUERY_TYPE = 'query-type';
export const TAGS = 'tags';



@Injectable({
    providedIn: 'root',
})
export class FileListService {
    constructor(
        private http: HttpClient,
        private keycloakService: KeycloakService
    ) {}

    /**
     * Get a list of files for this user/customer
     * @param company - what is the company to query
     */
    getFiles(company: string): Observable<GetFilesResponse[]> {
        console.log(`FileListService.getFiles(${company})`);
        let headers = new HttpHeaders();
        headers = headers.set(API_HEADER_COMPANY, company);
        headers = headers.set('Content-Type', 'application/json');  // Ensure Content-Type is set
        headers = headers.set('Accept', 'application/json, text/plain, */*');

        return this.http
            .get<GetFilesResponse[]>(
                `${GlobalVars.appConfig.questionServiceUrl}/files/`,
                { headers: headers }
            )
            .pipe(catchError(UtilsService.handleError));
    }


    uploadFiles(company: string, files: File[], tags: string): Observable<any> {
        const formData: FormData = new FormData();
        files.forEach((file) => {
          formData.append('files', file, file.name);
        });

        let headers = new HttpHeaders();
        headers = headers.set(API_HEADER_COMPANY, company);
        headers = headers.set(TAGS, tags);

        return this.http.post(`${GlobalVars.appConfig.questionServiceUrl}/files/upload`, formData, { headers });
      }



    deleteFile(company: string, fileItem: GetFileStruture) {
        console.log(`FileListService.deleteFile(${fileItem.filename})`);
        let headers = new HttpHeaders();
        headers = headers.set(API_HEADER_COMPANY, company);
        return this.http
            .delete(
                `${GlobalVars.appConfig.questionServiceUrl}/files/${fileItem.file_id}`,
                { headers: headers }
            )
            .pipe(catchError(UtilsService.handleError));
    }

    downloadFile(company: string, file_id: string) {
        console.log(`FileListService.downloadFile(${file_id})`);
        let headers = new HttpHeaders();
        headers = headers.set(API_HEADER_COMPANY, company);
        return this.http.get(`${GlobalVars.appConfig.questionServiceUrl}/files/download/${file_id}`, { headers: headers, responseType: 'blob' })
            .pipe(catchError(UtilsService.handleError));
    }


}
