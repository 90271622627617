export enum RuntimeEnvironment {
  prod = 'prod',
  dev = 'dev',
  local = 'local',
  test = 'test',
}

export class AppConfiguration {
  environment = '';
  production = false;
  questionServiceUrl = '';
  dpaUrl = '';
  dpaApiKey = '';
}

export class GlobalVars {
  public static appConfig: AppConfiguration;
}

/**
 * Initialise this for junit testing.
 */
export class GlobalVarsJasmineTesting {
  public static jasmineMockAppConfig: AppConfiguration = {
    environment: RuntimeEnvironment.test,
    production: false,
    questionServiceUrl: 'http://mockQuestionServiceUrl:1234',
    dpaUrl:'https://dpa-api.sitalab.aero',
    dpaApiKey:'44e97b19-1d05-4300-94ee-903c08304a1e'

  };
}
