/**
 * Enum containing all the user actions we want to capture in the UI
 */
export enum GoogleAnalyticsEnum {
  EVENT_CATEGORY = 'ask_me_anything_form',
  get_list_sample_questions = 'user_get_list_sample_questions',
  select_language_model = 'user_select_language_model',
  user_toggle_sql_docs_query_type = 'user_toggle_sql_docs_query_type',
  user_init_new_conversation_session = 'user_init_new_conversation_session',
  user_thumbup = 'user_thumbup',
  user_thumbdown = 'user_thumbdown',
  user_ask_question = 'user_ask_question',
  user_open_source_document = 'user_open_source_document'
}

