<mat-card class="flex-container">
  <mat-card-header style="align-content: flex-start">
    <mat-card-title>Select a company account</mat-card-title>
    <!--    <mat-card-subtitle>Start</mat-card-subtitle>-->
  </mat-card-header>
  <mat-card-content style="align-items: center;">
    @if (allowedTenants) {
      <div>
        <mat-list>
          @for (t of allowedTenants; track t) {
            <mat-list-item>
              <button mat-button (click)="select(t)">{{t}}</button>
            </mat-list-item>
          }
        </mat-list>
      </div>
    }
    @if (!allowedTenants) {
      <div>
        The user isn't loaded properly. Try refreshing the page.
      </div>
    }
  </mat-card-content>
  <mat-card-actions style="align-content: flex-start">
    <button mat-button (click)="cancel()">Cancel</button>
  </mat-card-actions>
</mat-card>
