import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MatTableModule } from '@angular/material/table';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSliderModule } from '@angular/material/slider';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { AmaComponent } from './components/ama/ama.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { MatListModule } from '@angular/material/list';
import { AudioRecorderComponent } from './components/audio-recorder/audio-recorder.component';
import { DetailedAnswerDialogComponent } from './components/detailed-answer-dialog/detailed-answer-dialog.component';

import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { SelectCompanyDialog } from './components/select-company/select-company-dialog.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { NgxFileDropModule } from 'ngx-file-drop';
import { UploadFilesComponent } from './components/upload-files/upload-files.component';
import { SelectLLMDialog } from './components/select-llm/select-llm-dialog.component';
import {
  NgxGoogleAnalyticsModule,
  NgxGoogleAnalyticsRouterModule,
} from 'ngx-google-analytics';

// MatDialogModule

function initializeKeycloak(keycloak: KeycloakService) {
  return () => {
    console.log('initializeKeycloak');
    return keycloak.init({
      config: {
        // url: 'http://localhost:18080',
        url: 'https://ops-copilot-keycloak-v2.azurewebsites.net',
        realm: 'OperationsCoPilot',
        clientId: 'opscopilot',
      },
      enableBearerInterceptor: true,
      initOptions: {
        enableLogging: true,
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri:
          window.location.origin + '/assets/silent-check-sso.html',
      },
    });
  };
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    KeycloakAngularModule,
    FormsModule,
    MatIconModule,
    MatButtonModule,
    MatSelectModule,
    MatCardModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatTableModule,
    MatSliderModule,
    MatTooltipModule,
    MatMenuModule,
    MatSnackBarModule,
    MatToolbarModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatListModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    SelectCompanyDialog,
    SelectLLMDialog,
    NgxFileDropModule,
    DashboardComponent,
    AmaComponent,
    ToolbarComponent,
    UploadFilesComponent,
    AudioRecorderComponent,
    DetailedAnswerDialogComponent,
    NgxGoogleAnalyticsModule.forRoot('G-8CFE11SXK1'),
    NgxGoogleAnalyticsRouterModule,
  ],
  providers: [
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: KeycloakBearerInterceptor,
    //   multi: true
    // },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
