export abstract class LocalStorageConstants {

  static readonly LOGIN_EMAIL: string = 'OpsCoPilot-loginEmail';
  static readonly CURRENT_LOGGEDIN_USER: string = 'OpsCoPilot-currentUser';

  static readonly USER_WANTS_VOICCONTROL: string = 'OpsCoPilot-continiousVoiceControlActive';
  static readonly QUESTION: string = 'OpsCoPilot-QUESTION';

  static readonly SELECTED_TENANT: string = 'OpsCoPilot-SELECTED_TENANT';
  static readonly SELECTED_LLM: string = 'OpsCoPilot-SELECTED_LLM';
}
