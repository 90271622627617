<div
    class="container"
    [ngStyle]="{
        'background-color': showHideFileZone ? '#c5eafa' : '#f6f8fe',
        'border-color': showHideFileZone ? '#a7e5ff' : '#e2e8f2'
    }"
>
    <div class="body-container">
        <ngx-file-drop
            #ngxFile
            dropZoneLabel="Drop files here"
            (onFileDrop)="dropped($event)"
            [ngClass]="{
                'file-drag-zone': showHideFileZone
            }"
            (onFileOver)="fileOver($event)"
            (onFileLeave)="fileLeave($event)"
        >
            <ng-template
                ngx-file-drop-content-tmp
                let-openFileSelector="openFileSelector"
            >
                <div class="header-container">
                    <h2 class="title">Documents</h2>

                    <button
                        mat-fab
                        extended
                        (click)="openDialog()"
                        class="btn-upload"
                    >
                        <!-- upload icon -->
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="24"
                            viewBox="0 -960 960 960"
                            width="24"
                        >
                            <path
                                d="M260-160q-91 0-155.5-63T40-377q0-78 47-139t123-78q25-92 100-149t170-57q117 0 198.5 81.5T760-520q69 8 114.5 59.5T920-340q0 75-52.5 127.5T740-160H520q-33 0-56.5-23.5T440-240v-206l-64 62-56-56 160-160 160 160-56 56-64-62v206h220q42 0 71-29t29-71q0-42-29-71t-71-29h-60v-80q0-83-58.5-141.5T480-720q-83 0-141.5 58.5T280-520h-20q-58 0-99 41t-41 99q0 58 41 99t99 41h100v80H260Zm220-280Z"
                            />
                        </svg>
                        Upload
                    </button>
                </div>

                @if(dataShareService.selectedFiles.length < 1){
                <form class="filters" [formGroup]="searchForm">
                    <mat-select
                        class="select-input"
                        placeholder="Type"
                        formControlName="type"
                        (selectionChange)="onSearchChange()"
                        [ngStyle]="{
                            'background-color': showHideFileZone
                                ? '#c5eafa'
                                : 'white'
                        }"
                    >
                        <mat-option value="all">All</mat-option>
                        @for(ext of extensions; track ext) {
                        <mat-option value="{{ ext }}">{{
                            ext.toUpperCase()
                        }}</mat-option>
                        }
                    </mat-select>

                    <mat-select
                        class="select-input"
                        placeholder="Owner"
                        formControlName="owner"
                        (selectionChange)="onSearchChange()"
                        [ngStyle]="{
                            'background-color': showHideFileZone
                                ? '#c5eafa'
                                : 'white'
                        }"
                    >
                        <mat-option value="all">All</mat-option>
                        @for(email of emailsList; track email) {
                        <mat-option value="{{ email }}">{{ email }}</mat-option>
                        }
                    </mat-select>

                    <mat-select
                        class="select-input"
                        placeholder="Tags"
                        formControlName="tag"
                        (selectionChange)="onSearchChange()"
                        [ngStyle]="{
                            'background-color': showHideFileZone
                                ? '#c5eafa'
                                : 'white'
                        }"
                    >
                        <mat-option value="all">All</mat-option>
                        @for(tag of tags; track tag) {
                        <mat-option value="{{ tag }}">{{ tag }}</mat-option>
                        }
                    </mat-select>

                    <div class="search">
                        <input
                            placeholder="Search"
                            formControlName="search"
                            (change)="onSearchChange()"
                            (keyup)="onSearchChange()"
                            class="search-input"
                            matInput
                            [ngStyle]="{
                                'background-color': showHideFileZone
                                    ? '#c5eafa'
                                    : 'white'
                            }"
                        />
                        <mat-icon class="search-icon" matSuffix
                            >search</mat-icon
                        >
                    </div>
                </form>
                } @else {
                <div class="filters">
                    @for (element of filters; track element; let i = $index) {
                    <mat-chip-row (removed)="removeFilters(i)">
                        {{ element.value }}
                        <button matChipRemove>
                            <mat-icon>close</mat-icon>
                        </button>
                    </mat-chip-row>
                    } @if(filters.length > 0) {
                    <button (click)="clearFilters()" class="btn-clear">
                        Clear
                    </button>
                    }
                </div>
                } @if (mappedFiledResponses.length !==0){
                <app-list-selection
                    id="filesListScrollbar"
                    [searchValue]="searchForm.value"
                    [list]="mappedFiledResponses"
                    [filters]="filters"
                    (emptyFilters)="clearFilters()"
                    (onDeleteItem)="deleteFile($event)"
                    (onDownloadItem)="downloadDocByFileId($event)"                    
                ></app-list-selection>
                }

                <div class="btn-container">
                    <button
                        mat-fab
                        extended
                        [ngStyle]="{
                            visibility: showHideFileZone ? 'visible' : 'hidden'
                        }"
                        type="button"
                        (click)="openFileSelector()"
                        class="btn-upload"
                        style="width: 150px; height: 55px"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="24"
                            viewBox="0 -960 960 960"
                            width="24"
                        >
                            <path
                                d="M260-160q-91 0-155.5-63T40-377q0-78 47-139t123-78q25-92 100-149t170-57q117 0 198.5 81.5T760-520q69 8 114.5 59.5T920-340q0 75-52.5 127.5T740-160H520q-33 0-56.5-23.5T440-240v-206l-64 62-56-56 160-160 160 160-56 56-64-62v206h220q42 0 71-29t29-71q0-42-29-71t-71-29h-60v-80q0-83-58.5-141.5T480-720q-83 0-141.5 58.5T280-520h-20q-58 0-99 41t-41 99q0 58 41 99t99 41h100v80H260Zm220-280Z"
                            />
                        </svg>
                        Upload
                    </button>
                </div>

                @if (apiCalling) {
                <div class="loader-container">
                    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                </div>
                } @else if(mappedFiledResponses.length === 0) {
                <div class="empty-state-container">
                    <mat-divider></mat-divider>

                    <div class="empty-state">
                        <mat-icon fontIcon="search"></mat-icon>

                        <span>No files found</span>
                    </div>
                </div>
                }
            </ng-template>
        </ngx-file-drop>

        <!---->
    </div>
</div>
