<div class="container">
  <div class="header-container">
    <div class="title-container">
      <h2 class="title">Ask me anything ({{ queryType }})</h2>
      <span class="sub-title">Using language model {{ selectedLLM }}.</span>
    </div>

    <div class="actions-container">
      <button
        id="selectLanguageModel"
        mat-fab
        (click)="selectLanguageModel()"
        [disabled]="apiCalling"
        class="tooltip action-button"
      >
        <mat-icon>language</mat-icon>
        <span class="tooltiptext">Change language model</span>
      </button>

      <button
        id="toggleSQLorDocs"
        mat-fab
        (click)="toggleSQLorDocs()"
        [disabled]="apiCalling"
        class="tooltip action-button"
      >
        <mat-icon>change_circle</mat-icon>
        <span class="tooltiptext"
          >Toggle query type between SQL Data and Documents</span
        >
      </button>
      <button
        mat-fab
        (click)="createNewChatSession()"
        [disabled]="apiCalling"
        class="tooltip action-button"
      >
        <mat-icon>post_add</mat-icon>
        <span class="tooltiptext">Start new conversation</span>
      </button>
    </div>
  </div>

  <div class="chat-container" id="chatScrollbar">
    <ng-container *ngFor="let item of getQandAList(); trackBy: trackByItem">
      <div style="padding: 1em 10px 1em 0px">
        <div class="profile">
          <div
            class="name"
            [ngStyle]="{
              'background-color': item.isCoPilot ? '#f2772c' : '#0095da'
            }"
          >
            <span>
              {{
                item.isCoPilot
                  ? "CO"
                  : (
                      userProfile?.firstName?.charAt(0) +
                      "" +
                      userProfile?.lastName?.charAt(0)
                    ).toUpperCase()
              }}
            </span>
          </div>

          <div class="message">
            <span class="full-name">
              {{
                item.isCoPilot
                  ? "CoPilot"
                  : "You (" + userProfile?.firstName + ")"
              }}
            </span>
            <span class="text" [innerHTML]="getSanitizedContent(item)"></span>
            <!-- <a *ngIf="item.answer" href="javascript:void(0)" (click)="openDetailedAnswer(item.answer)">View Details</a> -->

            <div *ngIf="item.source" class="sources">
              <a
                href="javascript:void(0)"
                (click)="openDocument(item.source)"
                >{{ item.source }}</a
              >
            </div>
            <div *ngIf="item.showThumbs">
              <button
                mat-icon-button
                aria-label="Thumbs Up Icon Button"
                (click)="onThumbUpClicked(item)"
              >
                <mat-icon
                  [ngStyle]="
                    (item.thumbUpClicked && { color: 'green' }) || null
                  "
                  >thumb_up</mat-icon
                >
              </button>
              <button
                mat-icon-button
                aria-label="Thumbs Down Icon Button"
                (click)="onThumbDownClicked(item)"
              >
                <mat-icon
                  [ngStyle]="
                    (item.thumbDownClicked && { color: 'red' }) || null
                  "
                  >thumb_down</mat-icon
                >
              </button>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>

  <div style="display: flex; width: 100%; margin-top: auto">
    <form
      [formGroup]="form"
      (submit)="submit()"
      style="display: flex; width: 100%; gap: 1em"
    >
      <mat-form-field class="question-input-container" [style.width.%]="100">
        <mat-label>Type your Question Here...</mat-label>
        <input
          type="text"
          #inputAutoComplete
          placeholder="e.g. Summarise this document"
          aria-label="Enter a question"
          matInput
          [formControl]="autoCompleteQuestion"
          [matAutocomplete]="auto"
          [errorStateMatcher]="matcher"
        />
        <mat-hint *ngIf="!apiCalling">Type in your question...</mat-hint>
        <mat-error *ngIf="autoCompleteQuestion.hasError('badInput')">{{
          getErrorMessage()
        }}</mat-error>

        <span matSuffix class="audio-recorder-container">
          <app-audio-recorder
            (transcription)="onRecognizedText($event)"
          ></app-audio-recorder>
        </span>
        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
          @for (option of filteredOptions | async; track option) {
          <mat-option [value]="option">{{ option }}</mat-option>
          }
        </mat-autocomplete>
      </mat-form-field>
    </form>

    <button
      mat-fab
      (click)="getListOfSampleQuestions()"
      [disabled]="apiCalling"
      class="tooltip new-chat-button"
    >
      <mat-icon>assistant</mat-icon>
      <span class="tooltiptext"
        >Get suggested questions for this document(s)</span
      >
    </button>
  </div>

  <div>
    <mat-progress-bar
      *ngIf="apiCalling"
      mode="indeterminate"
    ></mat-progress-bar>
    <div *ngIf="apiError" class="api-error-msg">{{ apiError }}</div>
  </div>
</div>
