import { LocalStorageConstants } from '../../constants/local-storage-constants';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { DataShareService } from '../../services/data-share.service';
import { Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { QuestionService } from '../../services/question.service';
import { FileListService } from '../../services/file-list.service';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ServerAPIError } from '../../models/server-error.model';
import { GetFileStruture } from 'src/app/models/files.model';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { LLMAnswer } from '../../models/llmanswer.model';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { HttpClient } from '@angular/common/http';
import { KeycloakProfile } from 'keycloak-js';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';

import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { marked } from 'marked';

import {
  MatSnackBar,
  MatSnackBarRef,
  TextOnlySnackBar,
} from '@angular/material/snack-bar';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
  FormsModule,
  NgForm,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { UiQueryResponseModel } from '../../models/ui-query-response.model';
import { v4 as uuidv4 } from 'uuid';
import { AudioRecorderComponent } from '../audio-recorder/audio-recorder.component';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { DetailedAnswerDialogComponent } from '../detailed-answer-dialog/detailed-answer-dialog.component';
import {
  MatAutocompleteModule,
  MatAutocompleteTrigger,
} from '@angular/material/autocomplete';
import { map, Observable, startWith } from 'rxjs';
import { SelectLLMDialog } from '../select-llm/select-llm-dialog.component';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { GoogleAnalyticsEnum } from '../../constants/google-analytics-constants';

export enum EnumQueryType {
  Docs = 'Docs',
  SQL = 'SQL',
}

/**
 * We want to return error state, even if the input isn't touched.
 * This is needed for when switching between SQL and DOCS view.
 */
export class LLMQuestionErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    // console.log('isErrorState()')
    // const isSubmitted = form && form.submitted;
    return !!(control && control.invalid); // && (control.dirty || control.touched || isSubmitted));
  }
}

/**
 * Custom validator that factors in if the user is in DOCS or SQL query mode.
 * @param amaComponent
 */
export function llmQuestionValidator(amaComponent: AmaComponent): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (
      amaComponent.queryType === EnumQueryType.SQL &&
      control.value?.trim()?.length === 0
    ) {
      return { badInput: { value: control.value } };
    }
    if (control.value?.trim()?.length === 0) {
      return { badInput: { value: control.value } };
    }
    return null;
  };
}

/**
 * This is the ask me anything component.
 */
@Component({
  selector: 'app-ama',
  templateUrl: './ama.component.html',
  styleUrls: ['./ama.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatCardModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatProgressBarModule,
    AudioRecorderComponent,
    MatDialogModule,
    MatAutocompleteModule,
  ],
})
export class AmaComponent implements OnInit, OnChanges {
  @Input() userProfile: KeycloakProfile | null = null;
  @ViewChild(AudioRecorderComponent) audioRecorder!: AudioRecorderComponent; // Add this line
  @ViewChild(MatAutocompleteTrigger, { read: MatAutocompleteTrigger })
  inputAutoComplete!: MatAutocompleteTrigger;

  form!: FormGroup;
  autoCompleteQuestion!: FormControl;
  suggestedQuestions: string[] = [];
  public filteredOptions!: Observable<string[]>;

  apiError: string | undefined;
  selectedFiles!: GetFileStruture[];
  public apiCalling = false;
  private snackBarDialog!: MatSnackBarRef<TextOnlySnackBar>;
  private company!: string;
  private questionsAnswersDOCS: Array<UiQueryResponseModel> = [];
  private questionsAnswersSQL: Array<UiQueryResponseModel> = [];
  public answer!: LLMAnswer;
  errorMessage = '';
  commandType: 'voice' | 'text' = 'text';

  queryType: EnumQueryType = EnumQueryType.Docs;
  private welcomePrompt = '';
  matcher: LLMQuestionErrorStateMatcher = new LLMQuestionErrorStateMatcher();
  private conversationId: string = this.getExistingOrNewConversationId();
  selectedLLM = localStorage.getItem(LocalStorageConstants.SELECTED_LLM);

  constructor(
    private router: Router,
    private http: HttpClient,
    private formBuilder: FormBuilder,
    public dataShareService: DataShareService,
    private questionService: QuestionService,
    private fileListService: FileListService,
    private snackBar: MatSnackBar,
    private sanitizer: DomSanitizer,
    public matDialog: MatDialog,
    private gaService: GoogleAnalyticsService
  ) {
    this.initForm();

    if (!this.selectedLLM) {
      this.selectedLLM = SelectLLMDialog.allowedLLMs[0];
    }
  }

  async ngOnInit(): Promise<void> {
    this.setQueryType(EnumQueryType.Docs);
    const type = localStorage.getItem('AMA-TYPE');
    if (type === EnumQueryType.SQL.toString()) {
      this.setQueryType(EnumQueryType.SQL);
    } else {
      this.setQueryType(EnumQueryType.Docs);
    }
    this.dataShareService.selectedCompanyObservable.subscribe((newCompany) => {
      console.log(`AmaComponent. selected company updated! ${newCompany}`);
      this.company = newCompany;
    });

    this.dataShareService.selectedFilesObservable.subscribe((next) => {
      this.updateSelectedFiles(next);
    });
  }

  selectLanguageModel(): void {
    console.log('selectLanguageModel');

    const dialogRef = this.matDialog.open(SelectLLMDialog, {
      width: '250px',
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('SelectLLMDialog was closed, result=', result);
      if (result) this.selectedLLM = result;
      if (this.selectedLLM)
        this.gaService.event(
          GoogleAnalyticsEnum.select_language_model,
          GoogleAnalyticsEnum.EVENT_CATEGORY,
          `Change the LLM used ${this.selectedLLM}`
        );
      localStorage.setItem(
        LocalStorageConstants.SELECTED_LLM,
        this.selectedLLM as string
      );
    });
  }

  ngOnChanges(): void {
    if (this.questionsAnswersDOCS.length === 0 && this.userProfile?.firstName) {
      this.questionsAnswersDOCS.push(
        new UiQueryResponseModel(
          true,
          false,
          undefined,
          `Welcome ${this.userProfile?.firstName}! Please select one or more documents, and then Ask Me Anything.`,
          undefined,
          undefined
        )
      );
    }

    if (this.questionsAnswersSQL.length === 0 && this.userProfile?.firstName) {
      this.questionsAnswersSQL.push(
        new UiQueryResponseModel(
          true,
          false,
          undefined,
          `Welcome ${this.userProfile?.firstName}! Please ask a question about the AODB SQL data.`,
          undefined,
          undefined
        )
      );
    }
  }

  openDetailedAnswer(answer: string): void {
    this.matDialog.open(DetailedAnswerDialogComponent, {
      width: '500px',
      data: { answer },
    });
  }

  public async getListOfSampleQuestions() {
    this.gaService.event(
      GoogleAnalyticsEnum.get_list_sample_questions,
      GoogleAnalyticsEnum.EVENT_CATEGORY,
      'Get List of Sample Questions'
    );

    if (this.selectedFiles) {
      this.snackBar.open(
        'Getting list of sample questions for the selected document(s)...',
        'OK',
        {
          duration: 5000,
        }
      );
      console.log(`getting list of suggested questions...`);
      try {
        let question: string;
        const question_separator = '###';

        if (this.queryType === EnumQueryType.SQL) {
          question = `List the top 10 questions regarding airport operations, airline services, and flight details that a passenger or operations person can ask from this database schema. Take some sample data from sample records in questions. Separate each question with a triple hash sign (${question_separator}).`;
          this.company = 'db';
        } else if (this.company) {
          question = `Based on the content of the provided documents, identify and list the top 10 most relevant questions that these documents can answer. Summarize these questions without numbering them. Separate each question with a triple hash sign (${question_separator}). Ensure the questions are clear, well-formed, and specific to the content of the documents.`;
        } else {
          console.log(
            'Not getting list of questions - company not initialised properly.'
          );
          return;
        }

        this.autoCompleteQuestion.setValue('');
        this.apiCalling = true;
        this.suggestedQuestions = [];
        const response = await this.questionService
          .askQuestion(
            this.company,
            question,
            EnumQueryType.Docs,
            this.selectedFiles,
            this.conversationId,
            this.selectedLLM as string
          )
          .toPromise();
        this.apiCalling = false;

        if (response && response.answer) {
          this.suggestedQuestions = response.answer
            .split(question_separator)
            .filter((q) => q.trim() !== '');
          console.log(this.suggestedQuestions);

          this.filteredOptions = this.autoCompleteQuestion.valueChanges.pipe(
            startWith(''),
            map((value) => this._filter(value || ''))
          );
          if (this.inputAutoComplete) this.inputAutoComplete.openPanel();
          this.snackBar.open('Questions loaded...', 'OK', {
            duration: 5000,
          });
        }
      } catch (error) {
        this.apiCalling = false;
        console.error('Error fetching sample questions:', error);
      }
    } else {
      console.log('No files selected, not getting suggested questions.');
    }
  }

  setQuestionText(question: string) {
    this.form.get('question')?.setValue(question);
  }

  openDocument(source: string): void {
    this.gaService.event(
      GoogleAnalyticsEnum.user_open_source_document,
      GoogleAnalyticsEnum.EVENT_CATEGORY,
      `User checks source reference for answer`
    );

    const { fileId, filename: extractedFilename } =
      this.extractFileDetails(source);

    if (!fileId) {
      console.error('Error: File ID not present');
      return;
    }

    this.downloadDocByFileId(fileId, extractedFilename);
  }

  private downloadDocByFileId(fileId: string, fileName: string | null) {
    this.fileListService.downloadFile(this.company, fileId).subscribe(
      (response: Blob) => {
        const url = window.URL.createObjectURL(response);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${fileName}`;
        a.click();
        window.URL.revokeObjectURL(url);
      },
      (error) => {
        console.error('File download error:', error);
      }
    );
  }

  private extractFileDetails(source: string): {
    fileId: string | null;
    filename: string | null;
  } {
    const fileIdMatch = source.match(/file id: "([^"]+)"/);
    const filenameMatch = source.match(/doc: ([^;]+)/);
    return {
      fileId: fileIdMatch ? fileIdMatch[1] : null,
      filename: filenameMatch ? filenameMatch[1] : null,
    };
  }

  onRecognizedText(text: string) {
    this.commandType = 'voice'; // Add this line
    this.autoCompleteQuestion.setValue(text);
    this.submit();
  }

  private initForm() {
    console.log('initForm');
    const cachedQ = localStorage.getItem(LocalStorageConstants.QUESTION);
    console.log('cachedQ', cachedQ);

    this.autoCompleteQuestion = new FormControl(cachedQ, [
      llmQuestionValidator(this),
    ]);
    this.form = this.formBuilder.group(
      {
        question: this.autoCompleteQuestion,
      }
      // ,
      // {updateOn: 'blur'}
    );

    this.form.get('question')?.updateValueAndValidity();
  }

  submit() {
    if (this.audioRecorder.tempraryMute) {
      return;
    }
    this.commandType = 'text'; // Add this line
    console.log(`submit1 (${this.form.value.question})`);
    console.log(`submit2 (${this.form.get('question')?.value})`);

    this.errorMessage = this.getErrorMessage();

    if (!this.form.valid) {
      console.error('The form is not valid');
      return;
    }
    this.getQandAList().push(
      new UiQueryResponseModel(
        false,
        false,
        this.form.value.question,
        undefined,
        undefined,
        undefined
      )
    );
    this.scrollChatToBottom();
    this.askQuestion();
  }

  createNewChatSession() {
    this.gaService.event(
      GoogleAnalyticsEnum.user_init_new_conversation_session,
      GoogleAnalyticsEnum.EVENT_CATEGORY,
      `Start a new chat session.`
    );

    this.conversationId = this.generateNewConversationId();
    localStorage.setItem('conversation-id', this.conversationId);

    this.questionsAnswersDOCS = [];
    this.questionsAnswersSQL = [];

    if (this.queryType === EnumQueryType.Docs && this.userProfile?.firstName) {
      this.questionsAnswersDOCS.push(
        new UiQueryResponseModel(
          true,
          false,
          undefined,
          `Welcome ${this.userProfile?.firstName}! Please select one or more documents, and then Ask Me Anything.`,
          undefined,
          undefined
        )
      );
    } else {
      this.questionsAnswersSQL.push(
        new UiQueryResponseModel(
          true,
          false,
          undefined,
          `Welcome ${this.userProfile?.firstName}! Please ask a question about the AODB SQL data.`,
          undefined,
          undefined
        )
      );
    }
  }

  generateNewConversationId(): string {
    return uuidv4(); // Generate a new UUID for the conversation ID
  }

  getExistingOrNewConversationId(): string {
    const existingId = localStorage.getItem('conversation-id');
    return existingId ? existingId : this.generateNewConversationId();
  }

  private updateSelectedFiles(files: GetFileStruture[]) {
    console.log('AmaComponent.updateSelectedFiles', files);
    this.selectedFiles = files;
    this.form.get('question')?.updateValueAndValidity();
    this.createNewChatSession();
  }

  getSanitizedAnswer(answer: string | undefined): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(answer || '');
  }

  getSanitizedContent(item: UiQueryResponseModel): SafeHtml {
    const rawContent = item.isCoPilot ? item.answer : item.question;
    const markdownContent = marked.parse(rawContent || '') as string;
    return this.sanitizer.bypassSecurityTrustHtml(markdownContent);
  }

  private askQuestion() {
    this.gaService.event(
      GoogleAnalyticsEnum.user_ask_question,
      GoogleAnalyticsEnum.EVENT_CATEGORY,
      `Question asked!`
    );

    console.log(this.form.value);
    this.apiCalling = true;
    this.form.get('question')?.disable();

    this.apiError = undefined;

    // const question = this.question.value;
    const question = this.autoCompleteQuestion.value;
    localStorage.setItem(LocalStorageConstants.QUESTION, question);

    this.form.reset();

    this.questionService
      .askQuestion(
        this.company,
        question,
        this.queryType,
        this.selectedFiles,
        this.conversationId,
        this.selectedLLM as string
      )
      .subscribe({
        next: (answer) => {
          console.log('Answer received:', answer);
          this.answer = answer;

          const answer_to_display = answer.answer;
          const answer_to_speak = answer.short_answer || answer.answer;

          this.getQandAList().push(
            new UiQueryResponseModel(
              true,
              true,
              answer.query,
              answer_to_display,
              UiQueryResponseModel.getSourceText(answer),
              answer.questionUUID
            )
          );

          this.scrollChatToBottom();
          if (this.audioRecorder.isListening) {
            this.audioRecorder.convertTextToSpeech(answer_to_speak);
          }
        },
        error: (err: ServerAPIError) => {
          this.showError(err);
          this.apiCalling = false;
        },
        complete: () => {
          console.log('COMPLETE');
          this.apiCalling = false;
          this.form.get('question')?.enable();
        },
      });
  }

  scrollChatToBottom(): void {
    setTimeout(() => {
      const customScroll: HTMLElement | null =
        document.getElementById('chatScrollbar');
      if (customScroll) customScroll.scrollTop = customScroll.scrollHeight;
    }, 10);
  }

  private showError(error: ServerAPIError) {
    console.log('Error from ops backend : ', error);
    this.apiError = `${error.message} (${error.statusCode})`;
    if (this.snackBarDialog) {
      this.snackBarDialog.dismiss();
    }
    this.snackBarDialog = this.snackBar.open(this.apiError, undefined, {
      duration: 3000,
    });
  }

  getErrorMessage(): string {
    // if (this.queryType === EnumQueryType.Docs && this.selectedFiles.length == 0) {
    //   return 'You must select some documents & type in a question.';
    // }
    if (this.form.invalid && this.form.touched) {
      return 'You must enter a question';
    }
    return '';
  }

  toggleSQLorDocs() {
    this.conversationId = this.generateNewConversationId();

    if (this.queryType === EnumQueryType.Docs) {
      this.setQueryType(EnumQueryType.SQL);
    } else {
      this.setQueryType(EnumQueryType.Docs);
      this.welcomePrompt = `Welcome ${this.userProfile?.firstName}! Please select one or more documents, and then Ask Me Anything.`;
    }
    this.gaService.event(
      GoogleAnalyticsEnum.user_toggle_sql_docs_query_type,
      GoogleAnalyticsEnum.EVENT_CATEGORY,
      `Toggle the query type to ${this.queryType}`
    );
    this.form.get('question')?.updateValueAndValidity();
  }

  getQandAList(): UiQueryResponseModel[] {
    if (this.queryType === EnumQueryType.SQL) return this.questionsAnswersSQL;
    else return this.questionsAnswersDOCS;
  }

  setQueryType(queryType: EnumQueryType) {
    this.queryType = queryType;
    localStorage.setItem('AMA-TYPE', this.queryType.toString());
  }

  public onThumbUpClicked(item: UiQueryResponseModel) {
    this.gaService.event(
      GoogleAnalyticsEnum.user_thumbup,
      GoogleAnalyticsEnum.EVENT_CATEGORY,
      `Thumbs up!`
    );

    const originalThumbUpState = item.thumbUpClicked;
    const originalThumbDownState = item.thumbDownClicked;
    item.thumbUpClicked = !item.thumbUpClicked;
    if (item.thumbUpClicked) item.thumbDownClicked = false;
    console.log('Rate answer item is :', item);

    this.rateAnswer(item, originalThumbUpState, originalThumbDownState);
  }
  public onThumbDownClicked(item: UiQueryResponseModel) {
    this.gaService.event(
      GoogleAnalyticsEnum.user_thumbdown,
      GoogleAnalyticsEnum.EVENT_CATEGORY,
      `Thumbs down!`
    );

    const originalThumbUpState = item.thumbUpClicked;
    const originalThumbDownState = item.thumbDownClicked;

    item.thumbDownClicked = !item.thumbDownClicked;
    if (item.thumbDownClicked) item.thumbUpClicked = false;
    console.log(item);
    this.rateAnswer(item, originalThumbUpState, originalThumbDownState);
  }

  private rateAnswer(
    answer: UiQueryResponseModel,
    originalThumbUpState: boolean,
    originalThumbDownState: boolean
  ) {
    this.apiCalling = true;
    console.log('Rating answer for questionUUID :', answer.questionUUID);
    if (answer.questionUUID !== undefined && answer.questionUUID !== '') {
      this.questionService
        .rateAnswer(
          this.company,
          answer.questionUUID,
          answer.thumbUpClicked,
          answer.thumbDownClicked
        )
        .subscribe({
          next: (ratingUpdated) => {
            console.log(ratingUpdated);
            if (ratingUpdated) {
              this.snackBar.open('Thanks for your feedback', undefined, {
                duration: 3000,
              });
            } else {
              this.snackBar.open(
                'Sorry, there was a problem processing your feedback.',
                undefined,
                {
                  duration: 5000,
                }
              );
              this.restoreAnswerRating(
                answer,
                originalThumbUpState,
                originalThumbDownState
              );
            }
          },
          error: (err: ServerAPIError) => {
            this.showError(err);
            this.apiCalling = false;
            this.snackBar.open(
              'Sorry, there was a problem processing your feedback.',
              undefined,
              {
                duration: 5000,
              }
            );
            this.restoreAnswerRating(
              answer,
              originalThumbUpState,
              originalThumbDownState
            );
          },
          complete: () => {
            console.log('rateAnswer COMPLETE');
            this.apiCalling = false;
          },
        });
    } else {
      console.error(' questionUUID is empty so cant rate answer');
      this.apiCalling = false;
    }
  }

  trackByItem(index: number, item: UiQueryResponseModel): string | undefined {
    return item.questionUUID;
  }

  /**
   * If the answer rating failed to update on server, revert to the original rating in the UI
   * @param answer
   * @param originalThumbUpState
   * @param originalThumbDownState
   * @private
   */
  private restoreAnswerRating(
    answer: UiQueryResponseModel,
    originalThumbUpState: boolean,
    originalThumbDownState: boolean
  ) {
    answer.thumbUpClicked = originalThumbUpState;
    answer.thumbDownClicked = originalThumbDownState;
  }

  setCompany(_company: string) {
    this.company = _company;
  }

  /**
   * Filter for the autocomplete contol which shows suggested questions.
   * @param value
   * @private
   */
  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.suggestedQuestions.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }
}
