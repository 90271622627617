import {Component, inject, OnInit} from '@angular/core';
import {KeycloakService} from "keycloak-angular";
import {UserService} from "../../services/user.service";
import {LocalStorageConstants} from "../../constants/local-storage-constants";
import {KeycloakTokenParsed} from 'keycloak-js';
import {DialogModule, DialogRef} from "@angular/cdk/dialog";
import {MatCardModule} from "@angular/material/card";
import {MatListModule, MatSelectionList} from "@angular/material/list";

import {MatButtonModule} from "@angular/material/button";
import {MatDialogRef} from "@angular/material/dialog";
import {MatListItemSection} from "@angular/material/list/testing";

@Component({
  selector: 'app-select-llm',
  templateUrl: './select-llm-dialog.component.html',
  styleUrls: ['./select-llm-dialog.component.css'],
  standalone: true,
  imports: [DialogModule, MatCardModule, MatListModule, MatButtonModule],

})
export class SelectLLMDialog implements OnInit {

  public selectedLLM?: string | null;
  // These are the language models deployed on Azure, and configured in the `LLMs` config value in Azure operations-copilot-app-config
  static allowedLLMs: string[] = ["AZUREOPENAI_GPT4o","AZUREOPENAI_GPT4", "LLAMA31_405B"];
  readonly dialogRef = inject(MatDialogRef<SelectLLMDialog>);

  async ngOnInit(): Promise<void> {
    console.log('SelectLLMDialog.ngOnInit')
    this.selectedLLM = localStorage.getItem(LocalStorageConstants.SELECTED_LLM);
    console.log(SelectLLMDialog.allowedLLMs)
    console.log(this.selectedLLM)
  }

  public getAllowedLLMs() {
    return SelectLLMDialog.allowedLLMs;
  }
  cancel() {
    this.dialogRef.close(undefined)
  }

  select(selectedLLM: string) {
    this.dialogRef.close(selectedLLM)
  }
}
